@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  color: #666;
}
.search-input > span.ant-input-suffix {
  right: 0;
}
.ant-input-number .ant-input-number-handler-wrap {
  opacity: 1;
}
.cuiContentWrapper {
  max-width: 1366px;
  margin: 0 auto;
}
.clear-float {
  float: none;
  clear: both;
  display: block;
}
.page-title {
  text-align: left;
  font-size: 26px;
  font-weight: bold;
  color: #444;
  padding: 12px;
  margin-top: 15px;
}
a:focus {
  text-decoration: none;
  text-decoration-skip-ink: auto;
}
.ant-carousel .slick-dots li button {
  height: 0px;
}
.ant-carousel .slick-dots-bottom {
  bottom: -12px;
}
