.footer {
  font-size: 13px;
}

.newsletterSubscribe {
  background-color: #f5f5f5;
  height: 80px;
  text-align: center;

  .formItem {
    padding-top: 20px;

    .subscribeText {
      font-size: 21px;
    }

    .subscribeBtn {
      svg {
        margin-right: 5px;
      }
    }
  }
}

.footerCopyright {
  background-color: #656565;
  color: #fff;
  text-align: center;
  padding: 10px;
}

.sitemap {
  background-color: #eee;
  padding: 20px;

  .sitemapcol {
    padding-left: 20px;

    h3 {
      color: #444;
      font-size: 14px;
      font-weight: bold;
    }

    ul {
      list-style: none;
      padding: 10px 0 0;

      li {
        padding-bottom: 7px;

        a {
          color: #555;

          svg {
            margin-right: 5px;
          }
        }

        a:hover {
          color: @link-color;
        }
      }
    }

    ul.contactDetails {
      li {
        line-height: 23px;
        p {
          float: left;
        }
        svg {
          float: left;
          font-size: 21px;
          margin-right: 7px;
        }
      }
    }
  }
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;