.viewProduct {
  padding: 25px;

  :global(.page-title) {
    padding: 0;
  }
}

.spinner {
  margin-top: 75px;
  margin-bottom: 75px;
}

.imgGallery {
  margin-top: 20px;
}

.stockStatus {
  font-size: 16px;
  font-weight: bold;
  color: @primary-color;

  &.ARRIVING_SOON {
    color: @wplus-red-color;
  }

  &.IN_STOCK {
    color: @wplus-green-color;
  }
}

.priceBox {
  display: inline-block;

  :global(.price-box) {
    text-align: left;
    padding-left: 10px;
  }
}
.addInfo {
  font-size: 12px;
  font-style: italic;

  .infoContainer {
    display: flex;
    > div {
      padding: 0 3px 5px 3px;
    }
    > div:nth-child(1) {
      font-weight: bold;
      flex: 5%;
    }
    > div:nth-child(2) {
      flex: 95%;
    }
  }
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;