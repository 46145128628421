@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap);

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.minicart_MiniCart__UUlQp {
  padding-top: 40px;
  padding-left: 40px;
}
.minicart_miniCartContainer__35i9X {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: stretch;
  cursor: pointer;
}
.minicart_miniCartContainer__35i9X .minicart_icon__1F0Lb {
  background-color: #da2937;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  width: 40px;
  height: 40px;
  color: #fff;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.minicart_miniCartContainer__35i9X .minicart_cartItems__2WNfG {
  flex: 2 1;
  border: 1px solid #d9d9d9;
  border-left: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.minicart_miniCartContainer__35i9X .minicart_cartItems__2WNfG p {
  padding: 0;
  margin: 0;
  padding-left: 12px;
}
.minicart_miniCartContainer__35i9X .minicart_cartItems__2WNfG .minicart_title__14PPB {
  font-weight: bold;
  padding-top: 1px;
}

.login_loginBtn__STvpZ {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  display: inline-block;
  height: 34px;
  line-height: 30px;
  background: ;
  border: 0;
  cursor: pointer;
}
.login_loginBtn__STvpZ svg {
  margin-right: 2px;
}
.login_logoutBtn__vZm0T {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  display: inline-block;
  height: 34px;
  line-height: 30px;
  border: 0;
  cursor: pointer;
}
.login_logoutBtn__vZm0T svg {
  font-size: 13px;
  margin-left: 2px;
}
.login_row__1h8Tb {
  margin-bottom: 5px;
}

.megamenu_container__2yx2g {
  max-width: 1366px;
  margin: 0 auto;
}
nav {
  height: 50px;
}
nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  text-align: left;
}
nav li {
  display: inline-block;
}
nav a,
nav button {
  color: #fff;
  text-decoration: none;
  display: block;
  height: 50px;
  padding: 0.75em 1.75em;
  font-size: 15px;
  border-radius: 0;
  background: none;
  cursor: pointer;
  border: none;
}
nav a .megamenu_expandIcon__3J4Jd,
nav button .megamenu_expandIcon__3J4Jd {
  margin-left: 6px;
}
nav button span {
  margin-top: -2px;
}
nav li:hover {
  background: #444;
}
nav li:hover a,
nav li:hover button {
  color: #fff;
}
.megamenu_menuSub__11Yb0 {
  position: absolute;
  left: 0;
  background: #fff;
  border: 1px solid grey;
  width: 100%;
  display: none;
  color: #fff;
  padding: 24px;
  z-index: 999;
}
nav li:hover .megamenu_menuSub__11Yb0 {
  display: block;
}
.megamenu_menuSub__11Yb0 li {
  display: block;
}
.megamenu_menuSub__11Yb0 a:hover,
.megamenu_menuSub__11Yb0 button:hover {
  text-decoration: underline;
}
.megamenu_menuCategory__h-QLL {
  padding: 5px !important;
  padding-left: 8px !important;
  background-color: #da2937 !important;
  margin-bottom: 0.3em;
  height: 35px;
  text-align: left;
  width: 100%;
  border-radius: 0;
  color: #fff;
  font-size: 15px;
}
.megamenu_menuCategory__h-QLL:hover span {
  text-decoration: underline;
}
.megamenu_menuCategory__h-QLL:first-of-type {
  margin-top: 0;
}
.megamenu_menuSub__11Yb0 a,
.megamenu_menuSub__11Yb0 button {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}
.megamenu_listMenus__20owy {
  margin: 0;
  margin-bottom: 24px;
  padding: 0;
  list-style: none;
  text-align: left;
}
.megamenu_listMenus__20owy a,
.megamenu_listMenus__20owy button {
  color: #000;
  text-decoration: none;
  display: block;
  height: 25px;
}
.megamenu_listMenus__20owy a span,
.megamenu_listMenus__20owy button span {
  font-size: 14px;
}
.megamenu_listMenus__20owy .megamenu_listMenusItems__1ydMK a,
.megamenu_listMenus__20owy .megamenu_listMenusItems__1ydMK button {
  color: #444;
  text-decoration: none;
  display: block;
  height: 25px;
  padding-left: 4px;
}
.megamenu_listMenus__20owy .megamenu_listMenusItems__1ydMK a span,
.megamenu_listMenus__20owy .megamenu_listMenusItems__1ydMK button span {
  font-size: 14px;
}
.megamenu_listMenus__20owy .megamenu_listMenusItems__1ydMK a .megamenu_newMarker__haLUp,
.megamenu_listMenus__20owy .megamenu_listMenusItems__1ydMK button .megamenu_newMarker__haLUp {
  text-transform: uppercase;
  padding-left: 4px;
  color: #da2937;
  background: #ffffff;
  font-size: 10px;
  text-shadow: 1px 0px 1px #cccccc, 0px 1px 1px #eeeeee, 2px 1px 1px #cccccc, 1px 2px 1px #eeeeee, 3px 2px 1px #cccccc, 2px 3px 1px #eeeeee, 4px 3px 1px #cccccc, 3px 4px 1px #eeeeee, 5px 4px 1px #cccccc, 4px 5px 1px #eeeeee, 6px 5px 1px #cccccc, 5px 6px 1px #eeeeee, 7px 6px 1px #cccccc;
  position: relative;
  top: -3px;
}
.megamenu_listMenus__20owy .megamenu_listMenusItems__1ydMK a:hover {
  color: #da2937;
}
.megamenu_listMenus__20owy .megamenu_listMenusItems__1ydMK button:hover {
  color: #da2937;
}
.megamenu_menuCol1__XtOax {
  width: 25%;
}
.megamenu_menuCol2__1K2sQ {
  width: 50%;
}

.productloading_productLoadingContainer__BW-eG {
  border: 1px solid #ddd;
  margin-bottom: 25px;
}
.productloading_productLoadingContainer__BW-eG .productloading_boxImage__29npo {
  width: 100%;
  padding-top: 100%;
}
.productloading_productLoadingContainer__BW-eG .productloading_name__2d8Sy {
  height: 18px;
  margin: 15px 0;
  margin-left: 15px;
  margin-right: 15px;
}
@-webkit-keyframes productloading_placeHolderShimmer__2tVrS {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes productloading_placeHolderShimmer__2tVrS {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.productloading_animated-background__29C3i,
.productloading_productLoadingContainer__BW-eG .productloading_boxImage__29npo,
.productloading_productLoadingContainer__BW-eG .productloading_name__2d8Sy {
  -webkit-animation-duration: 1.25s;
          animation-duration: 1.25s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: productloading_placeHolderShimmer__2tVrS;
          animation-name: productloading_placeHolderShimmer__2tVrS;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #f6f6f6;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 800px 104px;
  position: relative;
}

.productloadinglist_name__3GB0E {
  height: 28px;
  margin-bottom: 20px;
  max-width: 400px;
  margin-top: 20px;
}
@-webkit-keyframes productloadinglist_placeHolderShimmer__2jIxw {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes productloadinglist_placeHolderShimmer__2jIxw {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.productloadinglist_animated-background__1dX6-,
.productloadinglist_name__3GB0E {
  -webkit-animation-duration: 1.25s;
          animation-duration: 1.25s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: productloadinglist_placeHolderShimmer__2jIxw;
          animation-name: productloadinglist_placeHolderShimmer__2jIxw;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #f6f6f6;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 800px 104px;
  position: relative;
}

.productlist_name__2sF_K {
  height: 28px;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: bold;
  color: #444;
  font-size: 24px;
}
.productlist_noProducts__1YDph {
  font-size: 14px;
  height: 300px;
}
.productlist_floatingCart__1ZSS_ {
  position: fixed;
  right: 50px;
  top: 45%;
  z-index: 200;
  cursor: pointer;
  padding: 8px;
}
.productlist_floatingCart__1ZSS_ .productlist_icon__3PsMA {
  font-size: 34px;
  color: #da2937;
  box-shadow: inset 0px 1px 0px 0px #ffffff;
  background: linear-gradient(to bottom, #ededed 5%, #dfdfdf 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ededed', endColorstr='#dfdfdf', GradientType=0);
  background-color: #e8ecf1;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border: 1px solid #ccc;
  display: inline-block;
  padding-top: 16px;
  padding-bottom: 14px;
  padding-left: 20px;
  padding-right: 24px;
  border-radius: 100%;
  text-shadow: 0px 1px 0px #ffffff;
  box-shadow: 2px 2px 2px 0px #999999;
}
.productlist_floatingCart__1ZSS_ .productlist_icon__3PsMA:hover {
  background: linear-gradient(to bottom, #dfdfdf 5%, #ededed 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#dfdfdf', endColorstr='#ededed', GradientType=0);
  background-color: #dfdfdf;
  color: #da2937;
}
.productlist_floatingCart__1ZSS_ .productlist_text__xgO4I {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding-top: 4px;
}
@media (max-width: 1366px) {
  .productlist_floatingCart__1ZSS_ {
    top: 65%;
  }
}

.productview_viewProduct__15FQD {
  padding: 25px;
}
.productview_viewProduct__15FQD .page-title {
  padding: 0;
}
.productview_spinner__3PgOq {
  margin-top: 75px;
  margin-bottom: 75px;
}
.productview_imgGallery__22FpT {
  margin-top: 20px;
}
.productview_stockStatus__28d6K {
  font-size: 16px;
  font-weight: bold;
  color: #da2937;
}
.productview_stockStatus__28d6K.productview_ARRIVING_SOON__10iAV {
  color: #42447f;
}
.productview_stockStatus__28d6K.productview_IN_STOCK__fxM6h {
  color: #03a678;
}
.productview_priceBox__2-KE3 {
  display: inline-block;
}
.productview_priceBox__2-KE3 .price-box {
  text-align: left;
  padding-left: 10px;
}
.productview_addInfo__3FeSc {
  font-size: 12px;
  font-style: italic;
}
.productview_addInfo__3FeSc .productview_infoContainer__27xaw {
  display: flex;
}
.productview_addInfo__3FeSc .productview_infoContainer__27xaw > div {
  padding: 0 3px 5px 3px;
}
.productview_addInfo__3FeSc .productview_infoContainer__27xaw > div:nth-child(1) {
  font-weight: bold;
  flex: 5% 1;
}
.productview_addInfo__3FeSc .productview_infoContainer__27xaw > div:nth-child(2) {
  flex: 95% 1;
}

.product_productContainer__2g8JV {
  border: 1px solid #ddd;
  margin-bottom: 25px;
}
.product_productContainer__2g8JV .product_boxImage__26gqW {
  position: relative;
  width: 100%;
}
.product_productContainer__2g8JV .product_boxImage__26gqW imgLink {
  display: block;
}
.product_productContainer__2g8JV .product_boxImage__26gqW img {
  max-width: 100%;
}
.product_productContainer__2g8JV .product_boxImage__26gqW .product_quickViewBtn__1pE3q {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 104px;
  height: 32px;
  margin-left: -52px;
  margin-top: -16px;
  display: none;
}
.product_productContainer__2g8JV .product_boxImage__26gqW:hover .product_quickViewBtn__1pE3q {
  display: block;
}
.product_productContainer__2g8JV .product_details__2hrnX {
  padding-left: 20px;
  padding-right: 20px;
}
.product_productContainer__2g8JV .product_details__2hrnX .product_name__1Gl3k {
  height: 60px;
  padding: 5px 0;
  overflow-y: hidden;
  text-align: center;
}
.product_productContainer__2g8JV .product_details__2hrnX .product_name__1Gl3k a {
  color: #444;
  line-height: 1.5em;
  font-weight: bold;
  font-size: 14px;
}
.product_stockStatus__22R3X {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-top: 39px;
  margin-bottom: 40px;
}
.product_stockStatus__22R3X.product_ARRIVING_SOON__2Y6jx {
  color: #42447f;
}
.product_stockStatus__22R3X.product_OUT_OF_STOCK__2sQcP {
  color: #da2937;
}
.product_stockStatus__22R3X.product_IN_STOCK__d2NH3 {
  color: #42447f;
}
.product_newIcon__wxHRj,
.product_specialPriceIcon__OFS6H,
.product_tag__1rqDl {
  position: absolute;
  max-width: 150px !important;
  top: -8px;
}
.product_newIcon__wxHRj {
  left: -8px;
}
.product_specialPriceIcon__OFS6H,
.product_tag__1rqDl {
  right: -12px;
}
.product_addInfo__2Gt9r {
  font-size: 12px;
  font-style: italic;
}
.product_addInfo__2Gt9r .product_infoContainer__8z7jG {
  display: flex;
}
.product_addInfo__2Gt9r .product_infoContainer__8z7jG > div {
  padding: 0 3px 5px 3px;
}
.product_addInfo__2Gt9r .product_infoContainer__8z7jG > div:nth-child(1) {
  font-weight: bold;
  flex: 20% 1;
}
.product_addInfo__2Gt9r .product_infoContainer__8z7jG > div:nth-child(2) {
  flex: 80% 1;
}

.attribute_attributeBox__3-hkG {
  margin-top: 20px;
  margin-bottom: 20px;
}
.attribute_attributeBox__3-hkG .attribute_attrLink__33dvc {
  display: block;
}
.attribute_attributeBox__3-hkG .attribute_attrLink__33dvc .attribute_attrImg__1n9fG {
  max-width: 100%;
}
.attribute_attributeBox__3-hkG .attribute_attrLink__33dvc .attribute_title__2ZmiE {
  text-align: center;
  padding-top: 16px;
  font-size: 15px;
}


.filters_title__12Cu4 {
  height: 28px;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #444;
}
.filters_filterTitle__2ftol {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}
.filters_filterCheckbox__1PZJY {
  margin: 0;
  line-height: 30px;
  display: block;
  margin-left: 8px;
}
.filters_refineBtnContainer__20z5T {
  margin-top: 12px;
  margin-bottom: 24px;
}
.filters_refineBtnContainer__20z5T .filters_refineBtn__172lL {
  float: right;
}
.filters_filterOptions__1gybO {
  max-height: 400px;
  overflow-y: auto;
}


.spinner_spining__1oI_N {
  margin-top: 20px;
  margin-bottom: 50px;
  width: 100%;
}

.cartsummary_summaryCard__1xrt_ {
  margin-bottom: 20px;
}
.cartsummary_summaryCard__1xrt_ > div:first-child {
  font-weight: bold;
}
.cartsummary_summaryCard__1xrt_ .cartsummary_shippingAlert__2sV-a {
  margin-bottom: 16px;
}
.cartsummary_summaryCard__1xrt_ .cartsummary_shippingAlert__2sV-a span {
  font-size: 12px;
}
.cartsummary_summaryCard__1xrt_ .cartsummary_summaryTable__GTTmM {
  margin-bottom: 16px;
  border-top: 1px solid #e8e8e8;
}
.cartsummary_summaryCard__1xrt_ .cartsummary_summaryTable__GTTmM table tr:last-child td {
  font-weight: bold;
}
.cartsummary_summaryCard__1xrt_ .cartsummary_summaryTable__GTTmM .cartsummary_summaryValue__Fiq2g {
  text-align: right;
}
.cartsummary_summaryCard__1xrt_ .cartsummary_summaryTable__GTTmM .cartsummary_summaryValue__Fiq2g .cartsummary_shippingCharges__F99Bn {
  font-weight: bold;
  color: #42447f;
}

.container {
  padding: 20px;
}
.addContainer .addressTitle {
  padding: 5px;
  font-weight: 600;
  color: grey;
  font-size: 13px;
  margin-bottom: 6px;
}
.addContainer .addName {
  font-weight: bold;
}
.addContainer .addLine,
.addContainer .addName {
  display: block;
  width: 100%;
  padding: 5px;
}

.imagegallery_imageGallery__ktemh {
  width: 100%;
}
.imagegallery_activeImage__1kLWf img {
  max-width: 100%;
  border: 1px solid #ddd;
}
.imagegallery_thumbnails__1a2Df img {
  width: 123px;
  margin: 5px;
  padding: 5px;
  border: 1px solid #ddd;
}
.imagegallery_thumbnails__1a2Df .imagegallery_active__1uMSi {
  border: 1px solid #42447f;
}

.pricebox_priceBox__khsjf {
  text-align: center;
  margin-bottom: 10px;
}
.pricebox_priceBox__khsjf .pricebox_regularPrice__kSnIE,
.pricebox_priceBox__khsjf .pricebox_specialPrice__2-RXf {
  font-size: 16px;
  color: #e74c3c;
}
.pricebox_priceBox__khsjf .pricebox_oldPrice__3sNU5 {
  font-size: 14px;
  color: #444;
  text-decoration: line-through;
  padding-right: 4px;
}
.pricebox_priceBox__khsjf .pricebox_bulkPricing__yYe2v {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: stretch;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin-top: 8px;
}
.pricebox_priceBox__khsjf .pricebox_bulkPricing__yYe2v :first-child {
  background: #eee;
}
.pricebox_priceBox__khsjf .pricebox_bulkPricing__yYe2v .pricebox_bulkPricingUnit__d8yLb {
  flex: 1 1 auto;
}
.pricebox_priceBox__khsjf .pricebox_bulkPricing__yYe2v .pricebox_bulkPricingUnit__d8yLb div {
  padding: 5px;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  font-weight: bold;
}
.pricebox_priceBox__khsjf .pricebox_bulkPricing__yYe2v .pricebox_bulkPricingUnit__d8yLb .pricebox_qty__2bD3d {
  background: #eee;
}
.pricebox_addToCartBox__3_gtV {
  text-align: center;
  margin-bottom: 20px;
}
.pricebox_addToCartBox__3_gtV .pricebox_qtyForm__1fFRM .pricebox_itemRow__3XLYG {
  margin-right: 0;
}
.pricebox_addToCartBox__3_gtV .pricebox_addToCartSpinner__HDeFN {
  width: 75px;
}
.pricebox_addToCartBox__3_gtV .pricebox_addToCartBtn__1_0XA {
  margin-left: 5px;
}
.pricebox_addToCartBox__3_gtV .pricebox_addToCartBtn__1_0XA:hover {
  background-color: #da2937 !important;
  color: #fff !important;
}
.pricebox_addToCartBox__3_gtV .pricebox_addToCartBtn__1_0XA svg {
  margin-right: 10px;
}
.pricebox_alreadyInCart__2xaAI {
  color: green;
  line-height: 2.5em;
  font-weight: bold;
}

.carousel_carousel__2StyH .carousel_slickslide__11iUO {
  text-align: center;
  width: 100%;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}
.carousel_carousel__2StyH .carousel_slickslide__11iUO h3 {
  color: #fff;
}
.carousel_slideImg__2j-Hf {
  width: 100%;
}
.slick-prev,
.slick-next {
  display: none !important;
}

.displayField_displayField__3qwxf {
  padding-left: 4px;
}
.displayField_displayField__3qwxf .displayField_label__2IY3L {
  font-weight: 600;
  color: grey;
  font-size: 13px;
  margin-bottom: 6px;
}
.displayField_displayField__3qwxf .displayField_value__2i3qi {
  height: 20px;
  font-size: 13px;
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.7);
}

.table_filterButton__Qmp3Y {
  display: inline-block;
}

.customerForm_sectionHeader__3017r {
  font-size: 18px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 20px;
}
.customerForm_hyperLink__AQ0bU {
  color: #da2937;
}

.phoneslider_latestPhones__2f4Kl {
  padding: 16px;
}
.phoneslider_phone__rZNU4 {
  width: 160px;
  height: 200px;
  float: left;
  overflow: hidden;
  margin-left: 10px;
  margin-right: 10px;
}
.phoneslider_title__28A7n {
  font-size: 24px;
  font-weight: bold;
  color: #555;
  padding-left: 10px;
  margin-bottom: 20px;
  margin-top: 15px;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover,
.slick-next:hover,
.slick-prev:focus,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Icons */
}
.slick-prev {
  left: -25px;
}
[dir='rtl'] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: '\2190';
}
[dir='rtl'] .slick-prev:before {
  content: '\2192';
}
.slick-next {
  right: -25px;
}
[dir='rtl'] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: '\2192';
}
[dir='rtl'] .slick-next:before {
  content: '\2190';
}
/* Dots */
.slick-dotted .slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: '\2022';
  width: 20px;
  height: 20px;
  font-family: 'slick';
  font-size: 25px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  content: '';
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

.navbar_menuBar__2VWDo {
  background: #d9d9d9;
  min-height: 48px;
  padding: 8px 16px;
  margin-bottom: 8px;
}
.navbar_menuBar__2VWDo .navbar_title__1fWXs {
  margin: 0;
}
.navbar_menuBar__2VWDo .navbar_actions__1AUx_ .navbar_actionsItems__37TJO {
  text-align: right;
}
.navbar_menuBar__2VWDo .navbar_actions__1AUx_ .navbar_actionsItems__37TJO > * {
  margin: 0 5px;
}
.navbar_menuBar__2VWDo .navbar_actions__1AUx_ .navbar_actionsItems__37TJO a,
.navbar_menuBar__2VWDo .navbar_actions__1AUx_ .navbar_actionsItems__37TJO button {
  color: gray;
  background-color: #eeee;
}
.navbar_menuBar__2VWDo .navbar_actions__1AUx_ .navbar_actionsItems__37TJO > * {
  margin-left: 8px;
}

.screenLoader_screenLoader__P206U {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  margin: 0;
  padding-top: 250px;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  text-align: center;
}
.screenLoader_screenLoader__P206U .fa-spin {
  font-size: 55px;
}

.login_loginBtn__2PGVN {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  display: inline-block;
  height: 34px;
  line-height: 30px;
  background: ;
  border: 0;
  cursor: pointer;
}
.login_loginBtn__2PGVN svg {
  margin-right: 2px;
}
.login_logoutBtn__1ixoq {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  display: inline-block;
  height: 34px;
  line-height: 30px;
  border: 0;
  cursor: pointer;
}
.login_logoutBtn__1ixoq svg {
  font-size: 13px;
  margin-left: 2px;
}
.login_row__13hjQ {
  margin-bottom: 5px;
}

.header_blinkText__3eC2f {
  -webkit-animation-name: header_blinker__2DFUn;
  -webkit-animation-duration: 4s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: header_blinker__2DFUn;
  -moz-animation-duration: 4s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  animation-name: header_blinker__2DFUn;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  color: red;
  background: #f5f5f5;
  padding: 5px 0;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}
@-webkit-keyframes header_blinker__2DFUn {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@keyframes header_blinker__2DFUn {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
.header_topNoticeBar__27odH {
  text-align: center;
  padding: 5px;
}
.header_accountInfoButtonsContainer__GHWhf {
  text-align: right;
  height: 34px;
}
.header_accountInfoBar__2laVv {
  background-color: #f5f5f5;
}
.header_accountInfoButtons__2_FR4 {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-block;
}
.header_accountInfoButtons__2_FR4 li {
  float: left;
}
.header_logo__2Ya6U {
  height: 128px;
  text-align: right;
  padding-right: 60px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.header_logo__2Ya6U img {
  max-height: 100%;
  max-width: 100%;
}
.header_search__1wOS_ {
  padding-top: 40px;
}
.header_navigationMenu__1Fbke {
  background-color: #656565;
  height: 50px;
}
.header_accountBtn__2km1k {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  display: inline-block;
  height: 34px;
  line-height: 30px;
  border: 0;
  cursor: pointer;
  background: #da2937;
  color: #fff;
}
.header_accountBtn__2km1k svg {
  margin-right: 2px;
}
.header_accountBtn__2km1k:hover {
  color: #ccc;
}

.footer_footer__3ERvC {
  font-size: 13px;
}
.footer_newsletterSubscribe__2VxpN {
  background-color: #f5f5f5;
  height: 80px;
  text-align: center;
}
.footer_newsletterSubscribe__2VxpN .footer_formItem__19Odq {
  padding-top: 20px;
}
.footer_newsletterSubscribe__2VxpN .footer_formItem__19Odq .footer_subscribeText__2hJuF {
  font-size: 21px;
}
.footer_newsletterSubscribe__2VxpN .footer_formItem__19Odq .footer_subscribeBtn__1ha-C svg {
  margin-right: 5px;
}
.footer_footerCopyright__2cmaF {
  background-color: #656565;
  color: #fff;
  text-align: center;
  padding: 10px;
}
.footer_sitemap__3W_EY {
  background-color: #eee;
  padding: 20px;
}
.footer_sitemap__3W_EY .footer_sitemapcol__P934R {
  padding-left: 20px;
}
.footer_sitemap__3W_EY .footer_sitemapcol__P934R h3 {
  color: #444;
  font-size: 14px;
  font-weight: bold;
}
.footer_sitemap__3W_EY .footer_sitemapcol__P934R ul {
  list-style: none;
  padding: 10px 0 0;
}
.footer_sitemap__3W_EY .footer_sitemapcol__P934R ul li {
  padding-bottom: 7px;
}
.footer_sitemap__3W_EY .footer_sitemapcol__P934R ul li a {
  color: #555;
}
.footer_sitemap__3W_EY .footer_sitemapcol__P934R ul li a svg {
  margin-right: 5px;
}
.footer_sitemap__3W_EY .footer_sitemapcol__P934R ul li a:hover {
  color: #333333;
}
.footer_sitemap__3W_EY .footer_sitemapcol__P934R ul.footer_contactDetails__3Tp05 li {
  line-height: 23px;
}
.footer_sitemap__3W_EY .footer_sitemapcol__P934R ul.footer_contactDetails__3Tp05 li p {
  float: left;
}
.footer_sitemap__3W_EY .footer_sitemapcol__P934R ul.footer_contactDetails__3Tp05 li svg {
  float: left;
  font-size: 21px;
  margin-right: 7px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.app_backTopBtn__2NXzI .ant-back-top-content {
  background: #656565;
}
@media (max-width: 1366px) {
  .app_backTopBtn__2NXzI {
    display: none;
  }
}

.widgets_title__2wwA1 {
  height: 28px;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: bold;
  color: #444;
  font-size: 24px;
}
.widgets_img__ydXck {
  max-width: 100%;
}
.widgets_staticImagesContainer__30Wu1 {
  margin-bottom: 25px;
}

.homepage_alertMsg__2gC4p {
  margin-top: 20px;
}
.homepage_carousel__1aaCc .homepage_slickslide__3ihpb {
  text-align: center;
  height: 160px;
  width: 100%;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}
.homepage_carousel__1aaCc .homepage_slickslide__3ihpb h3 {
  color: #fff;
}

.devices_devicesBox__3guqH {
  text-align: center;
  padding-bottom: 15px;
  min-height: 400px;
}

.device_deviceBox__3NXTN {
  margin-top: 20px;
  margin-bottom: 20px;
}
.device_deviceBox__3NXTN .device_deviceLink__3wMIv {
  display: block;
}
.device_deviceBox__3NXTN .device_deviceLink__3wMIv .device_deviceImg__3dfA0 {
  max-width: 100%;
}
.device_deviceBox__3NXTN .device_deviceLink__3wMIv .device_title__1X4Fb {
  text-align: center;
  padding-top: 16px;
  font-size: 15px;
}

.devicebrowser_deviceBrowserBox__2ppdE {
  margin-bottom: 32px;
}
.devicebrowser_deviceGroupBox__3UPqz {
  margin: 32px 0;
}
.devicebrowser_yearsBox__3HZBt span.ant-tag {
  font-size: 18px;
  padding: 8px 20px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
}
.devicebrowser_yearsBox__3HZBt .devicebrowser_deselectMark__2xcpD {
  font-size: 16px;
  margin-right: 6px;
  visibility: visible;
}
.devicebrowser_yearsBox__3HZBt .devicebrowser_deselectMark__2xcpD.devicebrowser_not_selected__32dn6 {
  visibility: hidden;
}



.viewcart_updateQtyBtn__QrWPQ {
  background: #f5f5f5;
  margin-left: 5px;
}
.viewcart_centeredColName__370bG {
  text-align: center !important;
}
.viewcart_emptyCart__31pkJ {
  margin: 15px;
  font-size: 16px;
  padding-bottom: 100px;
}
.viewcart_itemsTable__1q3pz {
  margin-bottom: 32px;
}
.viewcart_itemsTable__1q3pz tbody > tr > td {
  padding: 8px;
}
.viewcart_itemsTable__1q3pz thead tr th div {
  font-weight: bold;
}
.viewcart_globalErrors__1c9zd {
  margin-bottom: 16px;
}
.viewcart_globalErrorsList__21XEw {
  margin: 0;
}
.viewcart_clearCartBtn__QmaBN {
  display: inline;
  float: left;
  width: 180px;
  z-index: 990;
  margin-bottom: 16px;
}
.viewcart_removedItemsAlert__4bzSC {
  margin-bottom: 16px;
}
.viewcart_tooltipImage__1u5iC {
  max-width: 200px;
}

.steps_checkoutSteps__3ZHcc {
  margin: 20px;
  padding: 20px;
}

.shipping_shippingCheckout__3-O4- {
  margin-top: 30px;
}
.shipping_shippingAlert__S5sVp {
  margin-top: 20px;
  margin-bottom: 20px;
}
.shipping_methodName__34ahw,
.shipping_methodDesc__1COmj,
.shipping_methodCharges__2PFo1 {
  width: 225px;
  display: inline-block;
  padding-left: 15px;
}
.shipping_methodCharges__2PFo1 {
  color: #42447f;
  font-weight: bold;
}
.shipping_methodRadio__1ysFx {
  padding-bottom: 25px;
  padding-top: 25px;
  border-bottom: 1px solid #ccc;
}
.shipping_methodRadio__1ysFx > span:first-child {
  margin-left: 15px;
}
.shipping_addressItem__oHx8u .shipping_addressRadioGroup__48tNP {
  display: block;
}
.shipping_addressRadio__2J97- {
  padding: 25px;
  width: 100%;
  border: 2px solid #eee;
  margin-top: 20px;
}
.shipping_addressRadio__2J97-.ant-radio-wrapper-checked {
  border: 2px solid #42447f;
}
.shipping_addressRadio__2J97-.ant-radio-wrapper-checked .shipping_addSelectedIcon__1Q85m {
  display: block;
}
.shipping_addressRadio__2J97- .shipping_addSelectedIcon__1Q85m {
  color: #42447f;
  font-size: 28px;
  position: absolute;
  top: -3px;
  right: 0px;
  display: none;
}
.shipping_addressRadio__2J97- > span:first-child {
  display: none;
}
.shipping_addressRadio__2J97- > span:last-child {
  display: block;
}
.shipping_addName__2Liii {
  font-weight: bold;
}
.shipping_addLine__9R22r,
.shipping_addName__2Liii {
  display: block;
  width: 100%;
  padding: 5px;
}
.shipping_addNewAddBtnContainer__3B2F- {
  text-align: right;
}
.shipping_addressItem__oHx8u div.ant-form-explain,
.shipping_methodItem__3xSBo div.ant-form-explain {
  background: #42447f;
  color: #fff;
  padding: 5px;
  margin-top: 5px;
}

.checkout_sectionHeading__1793f {
  font-size: 22px;
  display: block;
  color: #333;
  padding-bottom: 12px;
  border-bottom: 2px solid #ccc;
}
.checkout_actionBtnContainer__2h6lD {
  text-align: right;
  margin-bottom: 150px;
  margin-top: 20px;
}
.checkout_actionBtnContainer__2h6lD .checkout_nextBtn__APc3L {
  width: 150px;
}

.payment_paymentCheckout__glvfb {
  margin-top: 30px;
}
.payment_methodName__2YYqa,
.payment_methodDesc__1yKhO {
  width: 225px;
  display: inline-block;
  padding-left: 15px;
}
.payment_methodDesc__1yKhO {
  width: 400px;
}
.payment_methodRadio__1UV9T {
  padding-bottom: 25px;
  padding-top: 25px;
  border-bottom: 1px solid #ccc;
}
.payment_methodRadio__1UV9T > span:first-child {
  margin-left: 15px;
}
.payment_addressItem__1TOAI .payment_addressRadioGroup__1RVBt {
  display: block;
}
.payment_addressRadio__3gBYl {
  padding: 25px;
  width: 100%;
  border: 2px solid #eee;
  margin-top: 20px;
}
.payment_addressRadio__3gBYl.ant-radio-wrapper-checked {
  border: 2px solid #42447f;
}
.payment_addressRadio__3gBYl.ant-radio-wrapper-checked .payment_addSelectedIcon__3ycli {
  display: block;
}
.payment_addressRadio__3gBYl .payment_addSelectedIcon__3ycli {
  color: #42447f;
  font-size: 28px;
  position: absolute;
  top: -3px;
  right: 0px;
  display: none;
}
.payment_addressRadio__3gBYl > span:first-child {
  display: none;
}
.payment_addressRadio__3gBYl > span:last-child {
  display: block;
}
.payment_addName__13uOl {
  font-weight: bold;
}
.payment_addLine__7GieI,
.payment_addName__13uOl {
  display: block;
  width: 100%;
  padding: 5px;
}
.payment_addNewAddBtnContainer__33JKd {
  text-align: right;
}
.payment_addressItem__1TOAI div.ant-form-explain,
.payment_methodItem__3VFZ3 div.ant-form-explain {
  background: #42447f;
  color: #fff;
  padding: 5px;
  margin-top: 5px;
}

.confirm_paymentCheckout__3x7H- {
  margin-top: 30px;
  font-size: 1.25em;
}
.confirm_paymentCheckout__3x7H- h3 {
  margin-top: 30px;
}
.confirm_viewCart__1wOa7 {
  margin-right: 10px;
}
.confirm_method__2IBP0 > span,
.confirm_addName__abSoH {
  font-weight: bold;
}

.success_iconContainer__3GRJv {
  text-align: center;
  font-size: 65px;
  color: green;
  margin-bottom: 30px;
  margin-top: 40px;
}
.success_msgContainer__1xi4F {
  text-align: center;
  font-size: 23px;
  line-height: 1.9;
  margin-bottom: 20px;
}
.success_btnContainer__3mX7j {
  text-align: center;
  margin-bottom: 60px;
}


.account\.home_container__1Ip86 {
  padding: 20px;
}
.account\.home_addressViewContainer__S7An4 {
  margin-bottom: 32px;
}
.account\.home_addNewAddBtn__26hFP {
  margin-bottom: 32px;
}
.account\.home_editAddBtn__lp15- {
  margin-top: 12px;
}
.account\.home_deleteBtn__4_Qem {
  margin-top: 12px;
  margin-left: 12px;
}

.orderview_table__1Prhf {
  width: 100%;
}
.orderview_table__1Prhf .orderview_row__CI02y {
  height: 30px;
  border-bottom: 1px solid grey;
}
.orderview_table__1Prhf .orderview_row__CI02y .orderview_product__1-YhV {
  text-align: left;
  font-weight: bold;
}
.orderview_table__1Prhf .orderview_row__CI02y .orderview_number__1wjLO {
  text-align: right;
}
.orderview_table__1Prhf .orderview_row__CI02y .orderview_total__3di9X {
  text-align: right;
  font-weight: bold;
}
.orderview_table__1Prhf .orderview_row__CI02y .orderview_header_number__29eV8 {
  text-align: right;
}
.orderview_orderTitle__2Nb-M {
  padding: 10px;
  border-bottom: 5px solid #f4a137;
}
.orderview_header__SYEX5 {
  background-color: grey;
}
.orderview_containt__1-OWo {
  padding: 20px !important;
}

.account_page__1JV6V .account_pageTitle__2nnUN {
  padding-top: 20px;
  font-size: 166.6%;
  color: #444;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  border-bottom: 5px solid #f4a137;
}


.invoiceview_table__ZDUxF {
  width: 100%;
}
.invoiceview_table__ZDUxF .invoiceview_row__3ZoVg {
  height: 30px;
  border-bottom: 1px solid #eee;
}
.invoiceview_table__ZDUxF .invoiceview_row__3ZoVg .invoiceview_product__ioz5R {
  padding-left: 16px;
  text-align: left;
  font-weight: bold;
}
.invoiceview_table__ZDUxF .invoiceview_row__3ZoVg .invoiceview_number__fGrfF {
  text-align: right;
}
.invoiceview_table__ZDUxF .invoiceview_row__3ZoVg .invoiceview_total__2zED2 {
  padding-right: 16px;
  text-align: right;
  font-weight: bold;
}
.invoiceview_table__ZDUxF .invoiceview_row__3ZoVg .invoiceview_header__2KX5d {
  padding: 16px;
}
.invoiceview_table__ZDUxF .invoiceview_row__3ZoVg .invoiceview_header_number__1AGco {
  padding: 16px;
  text-align: right;
}
.invoiceview_orderTitle__2_dq7 {
  border-bottom: 5px solid #f4a137;
}

.pageview_container__36W7B {
  max-width: 1366px;
  margin: 0 auto;
}
.pageview_container__36W7B .pageview_title__myN9b {
  padding: 20px;
  font-weight: bold;
}
.pageview_container__36W7B .pageview_content__jSdC1 {
  padding-left: 20px;
  font-size: 14px;
  line-height: 1.2;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.login_loginBtn__1fMP9 {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  display: inline-block;
  height: 34px;
  line-height: 30px;
  background: ;
  border: 0;
  cursor: pointer;
}
.login_loginBtn__1fMP9 svg {
  margin-right: 2px;
}
.login_logoutBtn__2CUXa {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  display: inline-block;
  height: 34px;
  line-height: 30px;
  border: 0;
  cursor: pointer;
}
.login_logoutBtn__2CUXa svg {
  font-size: 13px;
  margin-left: 2px;
}
.login_row__1X41p {
  margin-bottom: 5px;
}
.login_container__1mkS8 {
  padding: 20px;
}

.error_errorPage__I4r20 {
  text-align: center;
}
.error_icon__3RB6P {
  font-size: 55px;
  color: #da2937;
  margin-top: 30px;
  margin-bottom: 20px;
}
.error_message__1_ApV {
  font-size: 22px;
  margin-bottom: 20px;
}
.error_btn__1x9Es {
  margin-bottom: 30px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  color: #666;
}
.search-input > span.ant-input-suffix {
  right: 0;
}
.ant-input-number .ant-input-number-handler-wrap {
  opacity: 1;
}
.cuiContentWrapper {
  max-width: 1366px;
  margin: 0 auto;
}
.clear-float {
  float: none;
  clear: both;
  display: block;
}
.page-title {
  text-align: left;
  font-size: 26px;
  font-weight: bold;
  color: #444;
  padding: 12px;
  margin-top: 15px;
}
a:focus {
  text-decoration: none;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}
.ant-carousel .slick-dots li button {
  height: 0px;
}
.ant-carousel .slick-dots-bottom {
  bottom: -12px;
}

