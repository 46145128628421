.container {
  max-width: 1366px;
  margin: 0 auto;
  .title {
    padding: 20px;
    font-weight: bold;
  }
  .content {
    padding-left: 20px;
    font-size: 14px;
    line-height: 1.2;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;