.title {
  height: 28px;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #444;
}

.filterTitle {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}

.filterCheckbox {
  margin: 0;
  line-height: 30px;
  display: block;
  margin-left: 8px;
}

.refineBtnContainer {
  .refineBtn {
    float: right;
  }
  margin-top: 12px;
  margin-bottom: 24px;
}

.filterOptions {
  max-height: 400px;
  overflow-y: auto;
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;