.productLoadingContainer {
  border: 1px solid #ddd;
  margin-bottom: 25px;

  .boxImage:extend(.animated-background) {
    width: 100%;
    padding-top: 100%;
  }

  .name:extend(.animated-background) {
    height: 18px;
    margin: 15px 0;
    margin-left: 15px;
    margin-right: 15px;
  }
}

// Animation
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f6f6;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 800px 104px;
  position: relative;
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;