.name {
  height: 28px;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: bold;
  color: #444;
  font-size: 24px;
}
.noProducts {
  font-size: 14px;
  height: 300px;
}

.floatingCart {
  position: fixed;
  right: 50px;
  top: 45%;
  z-index: 200;
  cursor: pointer;
  padding: 8px;

  .icon {
    font-size: 34px;
    color: @primary-color;
    -moz-box-shadow: inset 0px 1px 0px 0px #ffffff;
    -webkit-box-shadow: inset 0px 1px 0px 0px #ffffff;
    box-shadow: inset 0px 1px 0px 0px #ffffff;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #ededed), color-stop(1, #dfdfdf));
    background: -moz-linear-gradient(top, #ededed 5%, #dfdfdf 100%);
    background: -webkit-linear-gradient(top, #ededed 5%, #dfdfdf 100%);
    background: -o-linear-gradient(top, #ededed 5%, #dfdfdf 100%);
    background: -ms-linear-gradient(top, #ededed 5%, #dfdfdf 100%);
    background: linear-gradient(to bottom, #ededed 5%, #dfdfdf 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ededed', endColorstr='#dfdfdf',GradientType=0);
    background-color: #e8ecf1;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border: 1px solid #ccc;
    display: inline-block;
    padding-top: 16px;
    padding-bottom: 14px;
    padding-left: 20px;
    padding-right: 24px;
    border-radius: 100%;
    text-shadow: 0px 1px 0px #ffffff;
    -webkit-box-shadow: 2px 2px 2px 0px rgba(153, 153, 153, 1);
    -moz-box-shadow: 2px 2px 2px 0px rgba(153, 153, 153, 1);
    box-shadow: 2px 2px 2px 0px rgba(153, 153, 153, 1);

    &:hover {
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #dfdfdf), color-stop(1, #ededed));
      background: -moz-linear-gradient(top, #dfdfdf 5%, #ededed 100%);
      background: -webkit-linear-gradient(top, #dfdfdf 5%, #ededed 100%);
      background: -o-linear-gradient(top, #dfdfdf 5%, #ededed 100%);
      background: -ms-linear-gradient(top, #dfdfdf 5%, #ededed 100%);
      background: linear-gradient(to bottom, #dfdfdf 5%, #ededed 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#dfdfdf', endColorstr='#ededed',GradientType=0);
      background-color: #dfdfdf;
      color: @primary-color;
    }
  }

  .text {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    padding-top: 4px;
  }
}

@media (max-width: 1366px) {
  .floatingCart {
    top: 65%;
  }
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;