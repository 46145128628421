.imageGallery {
  width: 100%;
}

.activeImage {
  img {
    max-width: 100%;
    border: 1px solid #ddd;
  }
}

.thumbnails {
  img {
    width: 123px;
    margin: 5px;
    padding: 5px;
    border: 1px solid #ddd;
  }

  .active {
    border: 1px solid @wplus-red-color;
  }
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;