.table {
  width: 100%;
  .row {
    height: 30px;
    border-bottom: 1px solid grey;
    .product {
      text-align: left;
      font-weight: bold;
    }
    .number {
      text-align: right;
    }
    .total {
      text-align: right;
      font-weight: bold;
    }
    .header_number {
      text-align: right;
    }
  }
}

.orderTitle {
  padding: 10px;
  border-bottom: 5px solid #f4a137;
}

.header {
  background-color: grey;
}

.containt {
  padding: 20px !important;
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;