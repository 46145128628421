.Header {
}

.blinkText {
  -webkit-animation-name: blinker;
  -webkit-animation-duration: 4s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: blinker;
  -moz-animation-duration: 4s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  animation-name: blinker;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  color: red;
  background: #f5f5f5;
  padding: 5px 0;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}

@-moz-keyframes blinker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blinker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blinker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.topNoticeBar {
  text-align: center;
  padding: 5px;
}

.accountInfoButtonsContainer {
  text-align: right;
  height: 34px;
}

.accountInfoBar {
  background-color: #f5f5f5;
}

.accountInfoButtons {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-block;
  li {
    float: left;
  }
}

.logo {
  height: 128px;
  text-align: right;
  padding-right: 60px;
  padding-top: 12px;
  padding-bottom: 12px;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.search {
  padding-top: 40px;
}

.navigationMenu {
  background-color: #656565;
  height: 50px;
}

.accountBtn {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  display: inline-block;
  height: 34px;
  line-height: 30px;
  border: 0;
  cursor: pointer;
  background: @primary-color;
  color: #fff;

  svg {
    margin-right: 2px;
  }

  &:hover {
    color: #ccc;
  }
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;