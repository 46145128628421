.container {
  padding: 20px;
}
.addContainer {
  .addressTitle {
    padding: 5px;
    font-weight: 600;
    color: grey;
    font-size: 13px;
    margin-bottom: 6px;
  }
  .addName {
    font-weight: bold;
  }
  .addLine,
  .addName {
    display: block;
    width: 100%;
    padding: 5px;
  }
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;