.sectionHeading {
  font-size: 22px;
  display: block;
  color: #333;
  padding-bottom: 12px;
  border-bottom: 2px solid #ccc;
}
.actionBtnContainer {
  text-align: right;
  margin-bottom: 150px;
  margin-top: 20px;

  .nextBtn {
    width: 150px;
  }
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;