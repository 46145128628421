/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.backTopBtn :global(.ant-back-top-content) {
  background: #656565;
}
@media (max-width: 1366px) {
  .backTopBtn {
    display: none;
  }
}
