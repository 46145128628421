.attributeBox {
  margin-top: 20px;
  margin-bottom: 20px;

  .attrLink {
    display: block;

    .attrImg {
      max-width: 100%;
    }
    .title {
      text-align: center;
      padding-top: 16px;
      font-size: 15px;
    }
  }
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;