.priceBox {
  text-align: center;
  margin-bottom: 10px;

  .regularPrice,
  .specialPrice {
    font-size: 16px;
    color: #e74c3c;
  }

  .oldPrice {
    font-size: 14px;
    color: #444;
    text-decoration: line-through;
    padding-right: 4px;
  }

  .bulkPricing {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: stretch;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    margin-top: 8px;

    :first-child {
      background: #eee;
    }

    .bulkPricingUnit {
      flex: 1 auto;
      div {
        padding: 5px;
        border-top: 1px solid #ccc;
        border-left: 1px solid #ccc;
        font-weight: bold;
      }

      .qty {
        background: #eee;
      }
    }
  }
}

.addToCartBox {
  text-align: center;
  margin-bottom: 20px;

  .qtyForm {
    .itemRow {
      margin-right: 0;
    }
  }
  .addToCartSpinner {
    width: 75px;
  }
  .addToCartBtn {
    margin-left: 5px;

    &:hover {
      background-color: @primary-color !important;
      color: #fff !important;
    }

    svg {
      margin-right: 10px;
    }
  }
}

.alreadyInCart {
  color: green;
  line-height: 2.5em;
  font-weight: bold;
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;