.menuBar {
  background: #d9d9d9;
  min-height: 48px;
  padding: 8px 16px;
  margin-bottom: 8px;

  .title {
    margin: 0;
  }
  .actions {
    .actionsItems {
      text-align: right;
      > * {
        margin: 0 5px;
      }
      a,
      button {
        color: gray;
        background-color: #eeee;
      }
      > * {
        margin-left: 8px;
      }
    }
  }
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;