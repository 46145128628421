.table {
  width: 100%;
  .row {
    height: 30px;
    border-bottom: 1px solid #eee;
    .product {
      padding-left: 16px;
      text-align: left;
      font-weight: bold;
    }
    .number {
      text-align: right;
    }
    .total {
      padding-right: 16px;
      text-align: right;
      font-weight: bold;
    }
    .header {
      padding: 16px;
    }
    .header_number {
      padding: 16px;
      text-align: right;
    }
  }
}

.orderTitle {
  border-bottom: 5px solid #f4a137;
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;