.deviceBrowserBox {
  margin-bottom: 32px;
}
.deviceGroupBox {
  margin: 32px 0;
}
.yearsBox {
  :global(span.ant-tag) {
    font-size: 18px;
    padding: 8px 20px;
    margin-bottom: 12px;
    border: 1px solid #ccc;
  }
    
  .deselectMark {
    font-size: 16px;
    margin-right: 6px;
    visibility: visible;
    &.not_selected {
      visibility: hidden;
    }
  }
}
@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;