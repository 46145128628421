.page {
  .pageTitle {
    padding-top: 20px;
    font-size: 166.6%;
    color: #444;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    border-bottom: 5px solid #f4a137;
  }
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;