.errorPage {
  text-align: center;
}

.icon {
  font-size: 55px;
  color: @primary-color;
  margin-top: 30px;
  margin-bottom: 20px;
}

.message {
  font-size: 22px;
  margin-bottom: 20px;
}

.btn {
  margin-bottom: 30px;
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;