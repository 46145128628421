.loginBtn {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    display: inline-block;
    height: 34px;
    line-height: 30px;
    background: ;
    border: 0;
    cursor: pointer;
  
    svg {
      margin-right: 2px;
    }
  }
  
  .logoutBtn {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    display: inline-block;
    height: 34px;
    line-height: 30px;
    border: 0;
    cursor: pointer;
  
    svg {
      font-size: 13px;
      margin-left: 2px;
    }
  }
  
  .row {
    margin-bottom: 5px;
  }
  .container{
    padding: 20px;
  }
  
@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;