.paymentCheckout {
  margin-top: 30px;
  font-size: 1.25em;
  h3 {
    margin-top: 30px;
  }
}

.viewCart {
  margin-right: 10px;
}

.method > span,
.addName {
  font-weight: bold;
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;