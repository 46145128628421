.displayField {
  padding-left: 4px;
  .label {
    font-weight: 600;
    color: grey;
    font-size: 13px;
    margin-bottom: 6px;
  }

  .value {
    height: 20px;
    font-size: 13px;
    margin-bottom: 12px;
    color: rgba(0, 0, 0, 0.7);
  }
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;