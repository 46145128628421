.updateQtyBtn {
  background: #f5f5f5;
  margin-left: 5px;
}

.centeredColName {
  text-align: center !important;
}

.emptyCart {
  margin: 15px;
  font-size: 16px;
  padding-bottom: 100px;
}

.itemsTable {
  tbody > tr > td {
    padding: 8px;
  }
  thead {
    tr {
      th {
        div {
          font-weight: bold;
        }
      }
    }
  }

  margin-bottom: 32px;
}

.globalErrors {
  margin-bottom: 16px;
}

.globalErrorsList {
  margin: 0;
}

.clearCartBtn {
  display: inline;
  float: left;
  width: 180px;
  z-index: 990;
  margin-bottom: 16px;
}

.removedItemsAlert {
  margin-bottom: 16px;
}

.tooltipImage {
  max-width: 200px;
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;