.productContainer {
  border: 1px solid #ddd;
  margin-bottom: 25px;

  .boxImage {
    position: relative;
    width: 100%;

    imgLink {
      display: block;
    }
    img {
      max-width: 100%;
    }

    .quickViewBtn {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 104px;
      height: 32px;
      margin-left: -52px;
      margin-top: -16px;
      display: none;
    }

    &:hover .quickViewBtn {
      display: block;
    }
  }

  .details {
    padding-left: 20px;
    padding-right: 20px;

    .name {
      height: 60px;
      padding: 5px 0;
      overflow-y: hidden;
      text-align: center;

      a {
        color: #444;
        line-height: 1.5em;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
}
.stockStatus {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-top: 39px;
  margin-bottom: 40px;

  &.ARRIVING_SOON {
    color: @wplus-red-color;
  }
  &.OUT_OF_STOCK {
    color: @primary-color;
  }
  &.IN_STOCK {
    color: @wplus-red-color;
  }
}
.newIcon,
.specialPriceIcon,
.tag {
  position: absolute;
  max-width: 150px !important;
  top: -8px;
}

.newIcon {
  left: -8px;
}
.specialPriceIcon,
.tag {
  right: -12px;
}
.addInfo {
  font-size: 12px;
  font-style: italic;

  .infoContainer {
    display: flex;
    > div {
      padding: 0 3px 5px 3px;
    }
    > div:nth-child(1) {
      font-weight: bold;
      flex: 20%;
    }
    > div:nth-child(2) {
      flex: 80%;
    }
  }
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;