.title {
  height: 28px;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: bold;
  color: #444;
  font-size: 24px;
}

.img {
  max-width: 100%;
}
.staticImagesContainer {
  margin-bottom: 25px;
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;