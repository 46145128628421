.carousel {
  .slickslide {
    text-align: center;
    width: 100%;
    line-height: 160px;
    background: #364d79;
    overflow: hidden;
  }
}

.carousel {
  .slickslide {
    h3 {
      color: #fff;
    }
  }
}

.slideImg {
  width: 100%;
}

:global(.slick-prev),
:global(.slick-next) {
  display: none !important;
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;