.alertMsg {
  margin-top: 20px;
}

.carousel {
  .slickslide {
    text-align: center;
    height: 160px;
    width: 100%;
    line-height: 160px;
    background: #364d79;
    overflow: hidden;
  }
}

.carousel {
  .slickslide {
    h3 {
      color: #fff;
    }
  }
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;