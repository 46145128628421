.container {
  padding: 20px;
}

.addressViewContainer {
  margin-bottom: 32px;
}

.addNewAddBtn {
  margin-bottom: 32px;
}

.editAddBtn {
  margin-top: 12px;
}

.deleteBtn {
  margin-top: 12px;
  margin-left: 12px;
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;