/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.MiniCart {
  padding-top: 40px;
  padding-left: 40px;
}
.miniCartContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: stretch;
  cursor: pointer;
}
.miniCartContainer .icon {
  background-color: #da2937;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  width: 40px;
  height: 40px;
  color: #fff;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.miniCartContainer .cartItems {
  flex: 2;
  border: 1px solid #d9d9d9;
  border-left: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.miniCartContainer .cartItems p {
  padding: 0;
  margin: 0;
  padding-left: 12px;
}
.miniCartContainer .cartItems .title {
  font-weight: bold;
  padding-top: 1px;
}
