.shippingCheckout {
  margin-top: 30px;
}
.shippingAlert {
  margin-top: 20px;
  margin-bottom: 20px;
}

.methodName,
.methodDesc,
.methodCharges {
  width: 225px;
  display: inline-block;
  padding-left: 15px;
}
.methodCharges {
  color: @wplus-red-color;
  font-weight: bold;
}

.methodRadio {
  padding-bottom: 25px;
  padding-top: 25px;
  border-bottom: 1px solid #ccc;

  > span:first-child {
    margin-left: 15px;
  }
}

.addressItem {
  .addressRadioGroup {
    display: block;
  }
}

.addressRadio {
  padding: 25px;
  width: 100%;
  border: 2px solid #eee;
  margin-top: 20px;

  &:global(.ant-radio-wrapper-checked) {
    border: 2px solid @wplus-red-color;

    .addSelectedIcon {
      display: block;
    }
  }

  .addSelectedIcon {
    color: @wplus-red-color;
    font-size: 28px;
    position: absolute;
    top: -3px;
    right: 0px;
    display: none;
  }

  > span:first-child {
    display: none;
  }

  > span:last-child {
    display: block;
  }
}
.addName {
  font-weight: bold;
}
.addLine,
.addName {
  display: block;
  width: 100%;
  padding: 5px;
}

.addNewAddBtnContainer {
  text-align: right;
}

.addressItem,
.methodItem {
  :global(div.ant-form-explain) {
    background: @wplus-red-color;
    color: #fff;
    padding: 5px;
    margin-top: 5px;
  }
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;