.screenLoader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  margin: 0;
  padding-top: 250px;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  text-align: center;

  :global(.fa-spin) {
    font-size: 55px;
  }
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;