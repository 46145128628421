.iconContainer {
  text-align: center;
  font-size: 65px;
  color: green;
  margin-bottom: 30px;
  margin-top: 40px;
}

.msgContainer {
  text-align: center;
  font-size: 23px;
  line-height: 1.9;
  margin-bottom: 20px;
}

.btnContainer {
  text-align: center;
  margin-bottom: 60px;
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;