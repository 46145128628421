.summaryCard {
  margin-bottom: 20px;

  > div:first-child {
    font-weight: bold;
  }

  .shippingAlert {
    span {
      font-size: 12px;
    }
    margin-bottom: 16px;
  }

  .summaryTable {
    margin-bottom: 16px;
    border-top: 1px solid #e8e8e8;

    table tr:last-child td {
      font-weight: bold;
    }

    .summaryValue {
      text-align: right;

      .shippingCharges {
        font-weight: bold;
        color: @wplus-red-color;
      }
    }
  }

  .checkoutBtn {
  }
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;