.latestPhones {
  padding: 16px;
}
.phone {
  width: 160px;
  height: 200px;
  float: left;
  overflow: hidden;
  margin-left: 10px;
  margin-right: 10px;
}
.title {
  font-size: 24px;
  font-weight: bold;
  color: #555;
  padding-left: 10px;
  margin-bottom: 20px;
  margin-top: 15px;
}

@primary-color: #da2937;@link-color: #333333;@wplus-red-color: #42447f;@wplus-green-color: #03a678;